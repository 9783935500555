<template>
  <div>
    <h2>レッスン登録情報</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-card
      title=""
      img-height="400px"
      :img-src="dataDic.lessonPic"
    >
      <div
        v-for="(item,index) in showList"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col lg="4">
            <div
              class="mb-75"
            >
              <span class="">
                {{ item.label }}
              </span>
            </div>
          </b-col>
          <b-col>
            <b-card-text
              v-if="showLabelNameList.indexOf(item.type) !== -1"
            >
              <div
                v-if="dataDic[item.key]"
              >
                {{ dataDic[item.key]['labelName'] }}
              </div>
            </b-card-text>
            <b-card-text
              v-else-if="item.type === 'learningCategoryInput'"
            >
              <div
                v-if="dataDic[item.key]"
              >
                {{ getLabel(dataDic[item.key]) }}
              </div>
            </b-card-text>
            <b-card-text
              v-else
            >
              <div
                v-if="dataDic[item.key]"
                class="text_"
              >
                {{ dataDic[item.key] }}
              </div>
              <div
                v-else
              >
                登録なし
              </div>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset-md="6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1 mt-2"
            @click="cancel"
          >
            戻る
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2"
            @click="edit"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BCardText,
} from 'bootstrap-vue'
import menulist from '@/components/conf/teacher/JPCAREER様_学習PF_講師_レッスン登録.json'
import categoryC from '@/components/conf/学習PF_小カテゴリ.json'
import categoryB from '@/components/conf/学習PF_大category.json'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
      showList: [],
      showLabelNameList: ['selectSingle', 'teacherSelectSingle'],
      categoryC,
      categoryB,
    }
  },
  mounted() {
    this.showList = this.menulist.filter(v => v.showTeacher === true)
  },
  methods: {
    edit() {
      this.$router.push({ path: '/teacher-lessonregistration-edit', query: { met: 'edit', id: this.$route.query.id } })
    },
    cancel() {
      this.$router.push({ name: 'teacher-lessonregistration-list' })
    },
    getLabel(val) {
      const ListC = categoryC.find(v => v.subjectId === val)
      // dataDic[item.key]['labelName'])['subjectLabel']
      const { groupId } = ListC
      const ListB = categoryB.find(v => v.groupId === groupId)
      /* eslint-disable prefer-template */
      const label = ListB.category + ' > ' + ListB.groupLabel + ' > ' + ListC.subjectLabel
      /* eslint-enable */
      return label
    },
  },
}
</script>

<style scoped>
.text_ {
  white-space: pre-wrap;
}
</style>
