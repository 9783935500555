<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmLessonRegistration :data-dic="dataDic" />
      <!-- <ConfirmLessonDateRegistration :data-dic="dataDic" /> -->
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmLessonRegistration from '@/components/teacher/lessonRegistration/ConfirmLessonRegistration.vue'
// import ConfirmLessonDateRegistration from '@/components/teacher/lessonRegistration/ConfirmLessonDateRegistration.vue'
import { mapGetters } from 'vuex'
import getSingleData from '@/firestore/data/getByDocID'
import Sidebar from '@/components/teacher/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmLessonRegistration,
    // ConfirmLessonDateRegistration,
    Sidebar,
  },
  data() {
    return {
      dataDic: {},
    }
  },
  computed: {
    ...mapGetters('lessonRegistrationBasic', ['lessonRegistrationList']),
  },
  async mounted() {
    const d = {
      collection: 'lessonRegistration',
      docID: this.$route.query.id,
    }
    const response = await getSingleData(d)
    if (response.status === 'success') {
      window.console.log('success', response.status)
      this.dataDic = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
